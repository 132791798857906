/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap'); */
@import 'mantine-datatable/styles.css';

.App {
  background-color: #fff;
}

.login {
  background: linear-gradient(
    180deg,
    rgba(253, 254, 178, 1) 0%,
    rgba(253, 254, 193, 1) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 20px;
  .login__logo {
    max-width: 100%;
  }
}

.header {
  background-color: #101113;
  height: 68px;
  .header__container {
    height: 68px;
  }
  .header__menu {
    font-weight: 500;
    a {
      color: #fff;
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-decoration: none;
    }
    a.active {
      border-bottom: 3px solid #fff;
    }
  }
}

.stockChangeModal .mantine-Modal-title {
  font-weight: 500;
}
